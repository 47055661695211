body {
  background-color: black;
}


#myVideo {
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 800px) {
  .bg-vid-container {
    position: absolute; right: 0; top: 0; z-index: -100;
    min-width: 100%; min-height: 100%; margin: auto;
  }
  
  #myVideo {
    width: 100%;
      height: 100vh; /* stretch to screen height */
  }
}

.menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
}

.link{
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.link:hover{
  color: lightcoral;
}

.Modal {
  position: absolute;
  top: 5em;
  left: 5em;
  right: 5em;
  bottom: 5em;
  background-color: papayawhip;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}

li {
  margin-bottom: 1em;
}

.close {
  position: absolute;
  right: 0.7em;
  top: 0.7em;
  padding: 0.2em;
  cursor: pointer;
  background-color: white;
  font-weight: bold;
  color: gray
}